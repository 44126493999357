
import {
  Form,
  Input,
  Select,
  Button,
  Space,
  Checkbox,
  Divider,
  InputNumber,
  notification,
  Card,
  Flex,
  Empty,
} from "antd";
import React from "react";
import Border from "../components/Border";
import { useState } from "react";
import { useEffect } from "react";
import axios, { API, Src, auth } from "../configs";
import { CaretDownOutlined, CaretRightOutlined, CaretUpOutlined } from "@ant-design/icons";
const optionDoc = ["Word Document", "HTML", "PDF", "Google Doc"];
const indent = ["None", "0.1", "0.2", "0.3", "0.4", '0.5', '0.6'];
const font = ["Tahoma"];

const renderDataTree = (dataDefault = [], search, ref = '') => {

  let min = Math.min(...dataDefault.map(x => x.name.split('.').length))

  var rs = dataDefault.filter(i => i.name.split('.').length === min)
    .sort((a, b) => parseInt(a.name.split('.').at(-1)) > parseInt(b.name.split('.').at(-1)) ? 1 : -1)
    .map(i => ({
      key: ref + '___' + i.section_id,
      section: i.name,
      sectionTitle: i.title,
      children: renderDataTree(dataDefault.filter(ii => ii.name.startsWith(i.name + '.')), search, ref + '___' + i.section_id)
    }))

  return rs;
}
const ShowButton = ({ show }) => <div className={(show ? 'show ' : '') + "show-btn"}><CaretRightOutlined /></div>
const Render = ({ data, check, setCheck, parent = "" }) => {
  const [show, setShow] = useState([]);
  const toggle = (id) => {
    let index = show.indexOf(id)
    if (index === -1) {
      setShow([...show, id])
    } else {
      setShow(show.filter(x => x !== id))
    }
  }
  const changeHandle = (item) => {
    if (check.find(x => x.key === item.key)) {
      setCheck(check.filter(x => !x.key.startsWith(item.key)))
    } else {
      let lst = check.filter(x => !x.key.startsWith(item.key))

      let arr = [{ key: item.key, section: item.section }];
      for (let i = 0; i < item.children.length; i++) {
        let element = item.children[i];

        if (!arr.find(x => x.section === element.section)) {
          arr.push({
            key: element.key,
            section: element.section
          })
        }
      }

      setCheck([...lst, ...arr])
    }
  }

  return (data.length === 0 && !parent) ? <Empty /> : data.map(i => <React.Fragment key={i.key} >
    <Card size="small" hoverable className="outline-item">
      <Flex>
        <Checkbox onChange={() => changeHandle(i)}
          checked={check.find(x => x.key === i.key) ? true : false}
          disabled={!check.find(x => x.key === parent) && parent !== ''} >
          <strong>Section {i.section}:</strong> {i.sectionTitle}
        </Checkbox>
        {i.children.length > 0 ? <div className="btn-view" onClick={() => toggle(i.key)} >
          <ShowButton show={!show.includes(i.key)} />
        </div> : null}
      </Flex>
    </Card>
    {!show.includes(i.key) ?
      <div style={{ marginLeft: 32, display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Render parent={i.key} check={check} setCheck={setCheck} data={i.children} />
      </div> : null}
  </React.Fragment>)
}

export default function Create() {
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tag, settag] = useState([]);
  const [brand, setBrand] = useState([]);
  const [itemBrand, setItemBrand] = useState({});
  const [tag_id, setTag_id] = useState();
  const [check, setCheck] = useState([])
  const [config, setConfig] = useState({
    font: "Tahoma",
    indent: '0.5',
    detailSize: 12,
    useColor: false,
    autoRename: true,
    type: "Word Document"
  });
  const [load, setLoad] = useState(false)
  const [show, setShow] = useState(false)
  function getJson() {
    const sectionList = check.map(x => x.key.split('___').pop())
    const josnData = {
      brand_id: itemBrand.brand_id,
      section_ids: sectionList,
      tag_id,
      config,
    };
    return josnData;
  }
  function checkSave() {
    return !(
      itemBrand?.brand_id && check.length > 0 &&
      config.type && config.font && config.indent
      && config.detailSize
    );
  }
  useEffect(() => {
    if (tag_id && itemBrand.brand_id) {
      setLoad(true)
      API.document
        .getForCreate({ brand_id: itemBrand.brand_id, industry_id: JSON.stringify(tag_id) })
        .then((rs) => {
          setdata(
            renderDataTree(rs.data.result)
          );
          setLoad(false)
          setCheck([])
        });
    }
  }, [itemBrand, tag_id]);
  useEffect(() => {
    var au = auth();
    API.tags
      .getAll()
      .then((response) => {
        settag(
          response.data.result.map((x) => ({
            label: x.name,
            value: x.industry_id,
          }))
        );
        return axios.get(`/documents/get-brands`, au);
      })
      .then((rs) => {
        setBrand(rs.data.result);
      })
      .catch(() => {
        settag([]);
      });
  }, []);
  const [form] = Form.useForm();
  const createDocument = async () => {
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;
    const data = getJson();
    console.log(data);

    setLoading(true)
    await axios
      .post(`/documents/create-document`, data, {
        headers: { Authorization: token },
      })
      .then(({ data }) => {
        if (data) {
          notification.success({ message: 'Success.', description: data.detail })
          setItemBrand({})
          setTag_id([])
          setdata([])
          form.resetFields()
        }
      })
      .catch(() => {
        notification.error({ message: "Error creating the document." });
      }).finally(() => setLoading(false))
  };
  return (
    <div style={{ overflow: 'auto', padding: 16, display: 'flex', flexDirection: 'column', gap: 8 }}>
      <div style={{ display: "flex", gap: "2rem" }}>
        <Border style={{ flex: 2 }} title="Brand Information">
          <Form form={form}
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            size="large"
          >
            <Form.Item label="Brand Name" required name="brand">
              <Select
                onChange={(id) => {
                  API.brand.getBrand({ id }).then(rs => {
                    setItemBrand(rs.data.result)
                    setTag_id([])
                  })
                }
                }
                placeholder="Seclect Brand"
                options={brand.map((x) => ({
                  label: x.name,
                  value: x.brand_id,
                }))}
              />
            </Form.Item>
            <Form.Item label="Brand Logo" required>
              {itemBrand.image ? (
                <img
                  alt=""
                  src={Src(itemBrand.image)}
                  height={100}
                />
              ) : (
                <div
                  style={{
                    fontSize: 18,
                    borderRadius: 20,
                    width: 120,
                    height: 120,
                    border: "1px solid #ddd",
                    color: "#ddd",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  LOGO
                </div>
              )}
            </Form.Item>
            <Form.Item label="Brand Location" required>
              <Input
                value={itemBrand.location}
                placeholder="Location"
                readOnly
              />
            </Form.Item>
            <Form.Item label="Industry" required>
              <Select mode="multiple"
                value={tag_id}
                placeholder="Please select"
                options={tag.filter(x => itemBrand.tags?.includes(x.value))}
                onChange={(x) => setTag_id(x)}
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </Form.Item>
            <div onClick={() => setShow(!show)}>
              <Divider orientation="left">Config {!show ? <CaretDownOutlined /> : <CaretUpOutlined />}</Divider>
            </div>
            {show && <div>
              <Form.Item label="Section font" required>
                <Select value={config.font} onChange={e => setConfig({ ...config, font: e })} placeholder="Seclect Font" options={font.map(x => ({ label: x, value: x }))} />
              </Form.Item>
              <Form.Item label="Section font size" required>
                <InputNumber value={config.detailSize} onChange={e => setConfig({ ...config, detailSize: e })} />
              </Form.Item>
              <Form.Item label="Indent" required>
                <Select value={config.indent} onChange={e => setConfig({ ...config, indent: e })} placeholder="Seclect Indent" options={indent.map(x => ({ label: x, value: x }))} />
              </Form.Item>
              <Form.Item label="Use Brand Colors" valuePropName="checked">
                <Checkbox checked={config.useColor} onChange={e => setConfig({ ...config, useColor: e.target.checked })} />
              </Form.Item>
              <Form.Item label="Auto section number" valuePropName="checked">
                <Checkbox checked={config.autoRename} onChange={e => setConfig({ ...config, autoRename: e.target.checked })} />
              </Form.Item>
            </div>}
          </Form>
        </Border>
        <Border disabled={load} title="Sections" style={{ flex: 4, gap: 4 }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 4, maxHeight: 'calc(100vh - 20rem)', overflow: 'auto', padding: 8 }}>
            <Render data={data} check={check} setCheck={setCheck} />
          </div>
        </Border>
      </div>
      <Space>
        <Select
          onChange={(e) => setConfig({ ...config, type: e })}
          size="large"
          value={config.type}
          placeholder="Please select"
          options={optionDoc.map((x) => ({ label: x, value: x }))}
        />
        <Button loading={loading}
          disabled={checkSave()}
          size="large"
          type="primary"
          onClick={() => createDocument()}
        >
          Create Template
        </Button>
      </Space>
    </div>
  );
}
