import React from 'react'

/**
 * 
 * @param {*} pa
 * @property {string} pa.title
 * @property {React.CSSProperties} pa.style
 * @returns 
 */
function Border({title, children, padding, style, className, align, disabled }) {
  return (
    <div className={'border '+className+(disabled?" dis":"")} style={{position: 'relative', border: '1px solid #ccc', padding: padding ?? 16, borderRadius: '10px', ...style}}>
        <div style={{background: '#fff', position: 'absolute', left: align === 'right'? undefined:'1rem', right: align === 'right'?'1rem': undefined, top: '-.75rem', padding: '0 8px', fontWeight: 'bold', zIndex: 10}}>{title}</div>
        {children}
    </div>
  )
}

function Divider({column, style}){
  return <div style={{...style, height:1, background: '#ccc', marginBottom: 16 }}></div>
}

Border.Divider = Divider

export default Border
