import React from 'react';
// import Button from '@mui/material/Button';
import axios from '../configs'
import { Link, useNavigate } from "react-router-dom";
import { useState } from 'react'
// import { IconButton, Tooltip } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { ExclamationCircleFilled, LockOutlined, UnlockOutlined } from '@ant-design/icons';
import Utils from "../utils/utils";
import { Space, Table, Tag, Button, Tooltip, Flex, Modal, Form, Input, List, Card, Descriptions, notification,Select } from 'antd';
import { DeleteFilled, PlusOutlined, EyeFilled, SearchOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import AddIcon from '@mui/icons-material/Add';
import AddUser from './AddUser';
import HighText from '../components/HL'
import UpdateRoleUser from './UpdateRoleUser'
import { GenData } from '../components/Description';
import { useAppStore } from '../utils/appstate';
import { API } from "../configs";

const User_Management = () => {
  //const { onClose } = props;
  const history = useNavigate();
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState()
  const [userData, setUserData] = React.useState([]);
  const [form] = Form.useForm();
  const [formadd] = Form.useForm();
  const [modal, setModal] = useState({})
  const [modalAdd, setModalAdd] = useState({})
  const [userRoles, setuserRoles] = React.useState([]);
  // const [infoLoginUsser, setInfoLoginUser] = useState({
  //   username: "",
  //   email: "",
  //   id: "",
  //   phone_number: "",
  //   password: "",
  //   key: "",
  //   confirmpass: "",
  //   is_active: 1,
  //   locked: 1,
  // })
  // Register Form
  const [formRegister, setFormRegister] = useState({
    name: "",
    username: "",
    email: "",
    phone_number: "",
    password: "",
    key: "",
    confirmpass: "",
    locked: 1,
    is_active: 1
  })
  // update role model
  // const [listRoleUser, setListRoleUser] = useState({
  //   userId: "",
  //   listRole: []
  // })



  const auth_token = localStorage.getItem("auth_token");
  const auth_token_type = localStorage.getItem("auth_token_type");
  const token = auth_token_type + " " + auth_token;
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState([]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  //   fetchData();
  // };
  const user = useAppStore((state) => state.AppInfo.user);

  const fetchData = async () => {
    try {
      setLoading(true)
      await axios.get(`/user-managements/getalluser`, {
        headers: { Authorization: token },
      })
        .then((response) => {
          let listUser = response.data.result.map(item => ({
            username: item.username,
            email: item.email,
            name: item.name,
            is_active: item.is_active,
            id: item.id,
            locked: item.locked,
            listRole: item.list_role_for_user
          }));
          // console.log("listUser", listUser);

          setUserData(listUser);
        }).finally(()=>setLoading(false))
    } catch (e) {
      debugger
      console.error(e);
    }
  }
  // const rows = userData;
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [allRole, setAllRole] = useState([])
  const handleDelete = (id) => {
    removeUser(id);
    setConfirmDialogOpen(false);
  };

  // remove user
  const removeUser = async (userid) => {

    const data = {

      id: userid,
      name: "",
      username: "",
      email: "",
      role_name: "",
      roleid: ""
    }
    await axios
      .post("/user-managements/remove-user-byid", data, { headers: { Authorization: token } })
      .then((response) => {

        fetchData();

        if(response && response.data) notification.success({message: response.data.detail});

      })
      .catch((error) => {
        // add error notif
        if(error.response && error.response.data) notification.error({message: error.response.data.detail});
        else notification.error({message: error.message});

      });
  };
  // get data
  React.useEffect(() => {
    fetchData();
    // getInfoUser();
    API.role.getAll().then(({ data }) => {
      if (data) {
          setAllRole(data.result)
      }
  })
  }, [])


  const unlockedUser = async (userid) => {

    const data = {
      userId: userid,
      locked: 0
    };
    await axios
      .post("/user-managements/unlocked-user-byid", data, { headers: { Authorization: token } })
      .then((response) => {

        fetchData();

        if(response && response.data) notification.success({message: response.data.detail});

      })
      .catch((error) => {

        if(error.response && error.response.data) notification.error({message: error.response.data.detail});
        else notification.error({message: error.message});

      });
  };

  const lockedUser = async (userid) => {

    const data = {
      userId: userid,
      locked: 1
    }
    await axios
      .post("/user-managements/locked-user-byid", data, { headers: { Authorization: token } })
      .then((response) => {

        fetchData();

        if(response && response.data) notification.success({message: response.data.detail});

      })
      .catch((error) => {
        // add error notif
        if(error.response && error.response.data) notification.error({message: error.response.data.detail});
        else notification.error({message: error.message});

      });
  };

  //// modal

  function validateForm() {
    if (formRegister.name.length > 0 && formRegister.email.length > 0
      && formRegister.password.length > 0 && formRegister.confirmpass.length > 0) {
      return true;
    }

    return false;

  }
  // const onChangeForm = (label, event) => {
  //   switch (label) {
  //     case "name":
  //       setFormRegister({ ...formRegister, name: event.target.value });
  //       break;
  //     case "username":
  //       setFormRegister({ ...formRegister, username: event.target.value });
  //       break;
  //     case "email":
  //       // email validation
  //       const email_validation = /\S+@\S+\.\S+/;
  //       if (email_validation.test(event.target.value)) {
  //         setFormRegister({ ...formRegister, email: event.target.value });
  //       }
  //       break;

  //     case "phone_number":
  //       setFormRegister({ ...formRegister, phone_number: event.target.value });
  //       break;
  //     case "password":
  //       setFormRegister({ ...formRegister, password: event.target.value });
  //       break;

  //     case "key":
  //       setFormRegister({ ...formRegister, key: event });
  //       break;

  //     case "confirmpass":
  //       setFormRegister({ ...formRegister, confirmpass: event.target.value });
  //       break;
  //     default:
  //       break;
  //   }
  //   validateForm();
  // };

 

  // add new user
  const onSubmitHandlerAddNew = async (formData) => {

        await axios
      .post(`/user-managements/new_user`, formData,{
        headers: { Authorization: token },
      })
      .then((response) => {
        // move to sign in page
        // console.log("response", response);
        //setIsModalOpen(false);
        navigate("/opmanual/user-management");
        // add successfully notif
        if(response && response.data) notification.success({message: response.data.detail});
       
        setModalAdd({
          open: false,
          title: <>Add User</>,
          okText: 'Save',
          type: 'add',
          icon: <SaveOutlined />

        })
        fetchData();
      })
      .catch((error) => {
        // console.log("error", error);
        // add error notif
        if(error.response && error.response.data) notification.error({message: error.response.data.detail});
        else notification.error({message: error.message});

      });
  };

  // const showModal = () => {
  //   setIsModalOpen(true);
  //   setFormRegister({});
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  //   setFormRegister({});
  // };

  const EditAction = (item) => {
    //onSubmitGetRoleByUserId(item.id)
    // console.log(item);
    form.setFieldsValue({...item, roleid: item.listRole.map(x=>x.roleid)})
    setModal({
      open: true,
      title: <>Edit Role User</>,
      okText: 'Save',
      type: 'edit',
      icon: <SaveOutlined />

    })

  }

  const AddAction = () => {

      formadd.resetFields()
      setModalAdd({
      open: true,
      title: <>Add User</>,
      okText: 'Save',
      type: 'add',
      icon: <SaveOutlined />

    })

  }

  
  const onSubmitUpdateRole = async (formData) => {

    await axios
      .post(`/user-managements/update-role-user`, formData, {
        headers: { Authorization: token },
      })
      .then((response) => {

        navigate("/opmanual/user-management");
        setModal({
          open: false,
          title: <>Edit Role User</>,
          okText: 'Save',
          type: 'edit',
          icon: <SaveOutlined />

        })
        fetchData()
        // console.log(response)
        notification.success({message: "Success"});
        
      })
      .catch((error) => {
        // add error notif
        if(error.response && error.response.data) notification.error({message: error.response.data.detail});
        else notification.error({message: error.message});

      });

  }

  
  //  
  return (
    <>
      <Flex vertical style={{ maxWidth: 1024, margin: '0 auto' }}>
        <Flex style={{ padding: '0 1rem' }} justify='space-between' wrap='wrap' align='center'>
          <h3 style={{ textTransform: 'capitalize' }}>Users Management</h3>
          {/* <Button type='primary' onClick={showModal} icon={<PlusOutlined />}>Add new user</Button> */}
          <Button type='primary' onClick={() => AddAction()} icon={<PlusOutlined />}>Add new user</Button> 
        </Flex>
        <Space wrap style={{ padding: '0 1rem' }}>
          <Input onInput={(e)=>setSearch(e.target.value)} style={{ width: 250 }} prefix={<SearchOutlined />} placeholder='Search user' />
        </Space>
        <List loading={loading} style={{ flex: 1, overflow: 'auto', padding: 16 }} grid={{ column: 1 }}
          dataSource={userData.filter(x => !search || ([ x.name, x.email, x.listRole.map(i=>i.role_name).join(' ')].join(' ')).includes(search))} renderItem={item => 
          <List.Item>
            <Card hoverable >
              <Flex gap={16} justify='space-between'>
                <Card.Meta title={<><HighText search={search}>{item.name}</HighText></>} description={<Descriptions items={GenData({
                  Email: <HighText search={search}>{item.email}</HighText>,
                  Role: <Space size="small" wrap>{item.listRole.map(x => (<Tag key={x.roleid}><HighText search={search}>{x.role_name}</HighText></Tag>))}</Space>,
                  Status: <Tag color={item.locked === 0?'success': 'warning'}>{item.locked === 0 ? "Active" : "Deactivated"}</Tag>,
                })} />}></Card.Meta>
                <Space>
                  <Tooltip title={"Edit "}>
                    <Button onClick={() => EditAction(item)} icon={<EditOutlined />} />
                  </Tooltip>

                  {item.locked === 0 ? (
                    <Tooltip title="Deactivate" >
                      <Button icon={<UnlockOutlined />}
                        onClick={(event) => { lockedUser(item.id) }}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Active" >
                      <Button icon={<LockOutlined />}
                        onClick={(event) => { unlockedUser(item.id) }}
                      />

                    </Tooltip>
                  )


                  }
                </Space>
              </Flex>
            </Card>
          </List.Item>} />
        <UpdateRoleUser form={form} modal={[modal, setModal]} formAction={onSubmitUpdateRole} />
        <AddUser form={formadd} modal={[modalAdd, setModalAdd]} formAction={onSubmitHandlerAddNew} />
      </Flex>



    </>
  )

}

export default User_Management
