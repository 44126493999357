import { DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Card, Flex, Form, Input, List, Modal, Space, Tag, Tooltip, message, notification } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { API } from '../../configs'
import RoleModal from './Modal/RoleModal'
import HighText from '../../components/HL'
import { useAppStore } from '../../utils/appstate'
const model = 'role'
const rolesNotAction = ['user', 'admin', 'SA']
export default function RoleManagement() {
    const [data, setData] = useState([])
    const [modal, setModal] = useState({})
    const [load, setLoad] = useState({})
    const [form] = Form.useForm();
    const [search, setSearch] = useState()
    const [loading, setLoading] = useState(false)
    const [per, setPer] = useState([])
    const user = useAppStore((state) => state.AppInfo.user);
    const ResetData = useCallback(() => {
        setLoading(true)
        API.permission.getAll().then(({ data }) => {
            if (data) {
                setPer(data.result)
            }
            return API.role.getAll().then(({ data }) => {
                if (data)
                    setData(data.result)
            })
        }).finally(() => setLoading(false))
        
    }, [])
    useEffect(() => {
        ResetData();
    }, [ResetData])

    const AddAction = () => {
        form.resetFields();
        setModal({
            open: true,
            title: <>Add a new {model}</>,
            okText: 'Add',
            type: 'add',
            icon: <PlusOutlined />
        })
    }
    const EditAction = ({ id }) => {
        setLoad({ type: 'edit', id })
        API.role.get({ id }).then(({ data }) => {
            if (data) {
                form.setFieldsValue(data.result)
                setModal({
                    open: true,
                    title: <>Edit {model}</>,
                    okText: 'Save',
                    type: 'edit',
                    icon: <SaveOutlined />,
                    permission_id: data.result.permission_id
                })
            }
        }).finally(() => setLoad({}))

    }
    const RemoveAction = ({ id }) => {
        Modal.confirm({
            title: 'You definitely want to delete this ' + model,
            okText: 'Delete',
            okButtonProps: { danger: true },
            onOk: () => API.role.remove({ id }).then(({ data, error }) => {
                if (data) {
                    notification.success({ message: 'Success' })
                    setModal({});
                    ResetData();
                } else {
                    setModal(m => ({ ...m, status: false }));
                    notification.error({ message: error })
                }
            })
        })
    }
    const FormAction = (formData) => {
        if (data.find(x => x.role_name.toLowerCase() === formData.role_name.toLowerCase() && x.id !== formData.id)) {
            message.error('Role Name already exists');
        } else
            if (modal.type === 'add') {
                if (!formData.permission_id) formData.permission_id = []
                setModal(m => ({ ...m, status: true }))
                API.role.create({ data: formData }).then(({ data, error }) => {
                    if (data) {
                        notification.success({ message: 'Success' })
                        setModal({});
                        ResetData();
                    } else {
                        setModal(m => ({ ...m, status: false }));
                        notification.error({ message: error })
                    }
                })
            } else {
                setModal(m => ({ ...m, status: true }))
                API.role.update({ id: formData.id, data: formData }).then(({ data, error }) => {
                    if (data) {
                        notification.success({ message: 'Success' })
                        setModal({});
                        ResetData();
                    } else {
                        setModal(m => ({ ...m, status: false }));
                        notification.error({ message: error })
                    }
                })
            }
    }
    return (
        <Flex vertical style={{ maxWidth: 1024, margin: '0 auto' }}>
            <Flex style={{ padding: '0 1rem' }} justify='space-between' wrap='wrap' align='center'>
                <h3 style={{ textTransform: 'capitalize' }}>{model} management</h3>
                <Button type='primary' onClick={AddAction} icon={<PlusOutlined />}>Add a new {model}</Button>
            </Flex>
            <Space wrap style={{ padding: '0 1rem' }}>
                <Input onInput={(e) => setSearch(e.target.value)} value={search} style={{ width: 250 }} prefix={<SearchOutlined />} placeholder='Search permission' />
            </Space>
            <List loading={loading} style={{ flex: 1, overflow: 'auto', padding: 16 }} grid={{ column: 1 }} dataSource={data.filter(x => !search || ([x.role_name, x.num_permission, x.num_user].join(' ')).toLowerCase().includes(search.toLowerCase()))} renderItem={item => <List.Item>
                <Card hoverable>
                    <Flex justify='space-between'>
                        <Card.Meta title={<><HighText search={search}>{item.role_name}</HighText></>} description={<>
                            Permission: <span style={{lineHeight: 2}}>{item.permission.map((x, i)=><Tag key={i}>{per.find(i=>i.id === x.permission_id)?.name}</Tag>)}</span>
                        </>}></Card.Meta>
                        <Space>
                            <Tooltip title={"Edit " + model}>
                                <Button loading={load.type === 'edit' && load.id === item.id} onClick={() => EditAction(item)} icon={<EditOutlined />} />
                            </Tooltip>
                            {<Tooltip title={"Remove " + model}>
                                <Button onClick={() => RemoveAction(item)} danger icon={<DeleteOutlined />} />
                            </Tooltip>}

                        </Space>
                    </Flex>
                </Card>
            </List.Item>} />
            <RoleModal form={form} modal={[modal, setModal]} formAction={FormAction} />
        </Flex>
    )
}
