import { Tabs } from 'antd';
import React, { useState } from 'react'
import UpdateRoleUser from './UpdateRoleUser';
import UserDetail from './UserDetail';
export default function UsersManagementTabs() {
    const query = new URLSearchParams(window.location.search);
    const [tab, setTab] = useState("UserDetail")
    const user_id = query.get('userid');
    var items = [
        { label: "User Detail", key: "UserDetail", children: <UserDetail tab={tab} userid={user_id} />},
        { label: "Member of", key: "Memberof", children: <UpdateRoleUser tab={tab} setTab={setTab}/> }, 
        // { label: "Locked", key: "clocked", children: <LockedUser tab={tab} setTab={setTab}/> }, 
      ];
  return <Tabs defaultActiveKey="1" onTabClick={(key)=>setTab(key)} activeKey={tab} type="card" style={{margin: 16}} size="large" items={items} />;
}