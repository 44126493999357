import { Avatar, Checkbox, Divider, Form, Input, Modal, Select, Tree, theme } from "antd";
import { useCallback, useEffect, useState } from "react";
import { API } from "../../../configs";
import * as IconAntd from "@ant-design/icons"
export default function RoleModal({ modal: [item, setModal], formAction, form }) {
    const [permission, setPermission] = useState()
    const [selectPer, setselectPer] = useState([])
    const [allPer, setallPer] = useState([])
    const tokenTheme = theme.useToken().token
    /**
     * 
     * @param {Array} list 
     * @param {string|null} idparent 
     * @returns 
     */
    const Icon = ({ name }) => {
        let IconR = IconAntd[name]
        return <Avatar style={{ backgroundColor: tokenTheme.colorPrimary }} icon={IconR ? <IconR /> : null}></Avatar>
    }
    const toggleArray = useCallback((array, id, allPer) => {
        if (array.includes(id)) {
            var arr = allPer.filter(x => allPer.find(x => x.id === id).menu_id === x.parent_menu_id).map(x => x.id)
            return array.filter(x => x !== id && !arr.includes(x));
        }
        return [...array, id]
    }, [])
    const renderChildren = useCallback((list, idparent, node_parent) => {
        return list.filter(i => i.parent_menu_id === idparent)
            .map(i => ({ key: i.id, title: <Checkbox disabled={!selectPer.includes(node_parent) && idparent !== ''} onChange={() => setselectPer(x => toggleArray(x, i.id, allPer))} checked={selectPer.includes(i.id)} >{i.name}</Checkbox>, children: renderChildren(list, i.menu_id, i.id) }))
    }, [allPer, selectPer, toggleArray])
    const RenderData = useCallback((data) => {
        var all = []
        data.result.forEach(i => all = all.concat(i.permission))
        setallPer(all)
        setPermission(data.result)
    }, [])
    useEffect(() => {
        API.role.getPer().then(({ data }) => {
            if (data) {
                RenderData(data)
            }
        })
        if (item.permission_id && item.open) {
            setselectPer(item.permission_id)
        } else setselectPer([])
    }, [RenderData, item.open, item.permission_id])
    
    return <Modal {...item} onCancel={() => setModal({})} forceRender onOk={() => form.submit()} okButtonProps={{ loading: item.status, icon: item.icon }}>
        <Form autoComplete="off" defaultValue={{ permission_id: [] }} form={form} onFinish={(data) => formAction({ ...data, permission_id: selectPer })} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item name="id" hidden><Input /></Form.Item>
            <Form.Item name="role_name" label="Role Name" required rules={[{ required: true, message: 'Role name is required' }]}>
                <Input />
            </Form.Item>
            <Divider>Access permissions</Divider>
            {permission ? <Tree switcherIcon={<IconAntd.DownOutlined />} defaultExpandAll showLine treeData={permission.map(x => ({ key: x.id, title: <><Icon name={x.icon} /> {x.app_name}</>, children: renderChildren(x.permission, '') }))} /> : null}
        </Form>
    </Modal>
}