import { useState , useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import React from 'react'
import { toast } from 'react-toastify';
import axios from './../configs'
import './register.css';
import Utils from "../utils/utils";
import { useSearchParams } from 'react-router-dom';
import { Button, Flex, Form, Input, Modal, Select, Tag, Transfer } from "antd";
import Description from "../components/Description";

export default function Profile ({ modal: [item, setModalProfile] }) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    function callback(){
        navigate("/opmanual");
     }
     
     

    return <Modal {...item} onCancel={() => setModalProfile({...item,open:false})} footer={[<Button onClick={() => setModalProfile({...item,open:false})}>Cancel</Button>]}  >
                <Description data={item.data??{}} column={1}/>
           </Modal>
}


