import React from 'react';
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import axios from './../configs'
import { useNavigate } from 'react-router-dom';

function Migration() {

    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;

    const navigate = useNavigate();

    const updateRoleForVersion = async (event) => {
        event.preventDefault();

        const version = '1.0.0';
        const url = `/migrate?version=${version}`;

        const data = {
            version: '1.0.0'
        }



        await axios
            .post("/auth/update-role-for-version-v-1-0-0", data, { headers: { Authorization: token } })
            .then((response) => {

                toast.success(response.data.detail);

                navigate(url);

            })
            .catch((error) => {
                // add error notif
                toast.error(error.response.data.detail.message)

            });
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
            <h1 style={{ fontSize: '30px', marginBottom: '20px' }}>Migration database for version</h1>
            <Button onClick={updateRoleForVersion} style={{ fontSize: '26px' }}>Version 1.0.0</Button>
        </div>
    );
}

export default Migration;