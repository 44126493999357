import { Flex } from "antd";
import Description from "../components/Description";

export default function About() {
    const data = {
        "System Name": "SMB Operation Manual",
        "Version": "1.0.0.1",
        "Date Release": "01/01/2024",
        "Troubleshoot Information": <div>Send email with screenshots and summary of the message to <a href="mailto:phamiliartest@smbfranchising.com">Phamiliar Tech Support</a> </div>
    }
    return <Flex justify="center">
        <div style={{ width: 500, gap: 16, display: 'flex', alignItems: 'center', flexDirection: 'column', }}>
            <img width={200} src="/static/background-main.jpg" alt="logo" />
            <Description data={data} column={1} />
        </div>
    </Flex>
}