import { Avatar, Button } from "@mui/material";
import React from "react";
import Utils from "../utils/utils";

const ExternalLogin = ({ props }) => {
  const googleLogin = () => {
    var auth_provider = "google-oidc"
    var login_url = props.producerLoginRedirectEndpoint + "?auth_provider=" + auth_provider
    window.location.href = login_url
  }

  const azureLogin = () => {
    // var auth_provider = "azure-oidc"
    // var login_url = props.producerLoginRedirectEndpoint + "?auth_provider=" + auth_provider
    // window.location.href = login_url
    window.location.href = `${Utils.ApiEndpoint}/auth/microsoft/login`
    //window.location.replace("/auth/microsoft/login")
  }

    
    const authenticate = () => {
      var authToken = (window.location.search.match(/authToken=([^&]+)/) || [])[1]
      if(authToken){
        localStorage.setItem("auth_token", authToken);
        localStorage.setItem("auth_token_type", "Bearer");
        window.location.replace("/")
        }

    }


      React.useEffect(() => {
        authenticate();
    }, [])


  return (
    <section>
      {/* <div>
        <Button onClick={googleLogin} variant="outlined" startIcon={<Avatar variant="square" alt="Google" src="/google.svg" />}>
          Sign in with Google
        </Button>
      </div> */}
      <div style={{padding: '15px'}}>
        <Button onClick={azureLogin} variant="outlined" startIcon={<Avatar variant="square" alt="Microsoft" src="/static/microsoft.svg" />}>
         <span style={{textTransform: 'initial'}}>Sign in with Microsoft</span> 
        </Button>
      </div>
    </section>
  );
};

export default ExternalLogin;
