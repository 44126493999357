import React, { useEffect, useState } from "react";
import Border from "../../components/Border";
import {
  Avatar,
  Button,
  Col,
  ColorPicker,
  Empty,
  Flex,
  Form,
  Input,
  List,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
  Typography,
  notification,
} from "antd";
import Center from "../../components/Center";
import {
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
  SearchOutlined,
  SwitcherOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import UploadImage from "../../components/UploadImage";
import { API, Src } from "../../configs";
import { Guid } from "js-guid";
import { DeleteOutline } from "@mui/icons-material";
export default function BrandEdit({ tab }) {
  const [item, setItem] = useState({
    pColor: "#1677ff",
    sColor: "#ddd",
    tColor: "#fff",
    fColor: "#000",
  });
  const [tag, setTag] = useState([]);
  const [variable, setVariable] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingImg, setLoadingImg] = useState(true);
  const [search, setSearch] = useState("");
  const [open, setOpen] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentBrands = !search ? brands.slice(startIndex, endIndex) : handleSearchBrands(search).slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  function handleSearchBrands(search) {
    return brands.filter((x) =>
      (x.name + x.location).toLowerCase().includes(search.toLowerCase())
    );;
  }

  useEffect(() => {
    API.tags
      .getAll()
      .then((response) => {
        setTag(
          response.data.result.map((x) => ({
            label: x.name,
            value: x.industry_id,
          }))
        );
      })
      .catch(() => {
        setTag([]);
      });
    if (open) {
      setLoading(true);
      API.brand
        .getAll()
        .then((e) => {
          setBrands(e.data.result);
        })
        .finally(() => setLoading(false));
    }
  }, [open, setTag, tab]);

  function PullVariable() {
    API.variable.getByTag({ tagId: item.tags }).then((e) => {
      let lst = e.data.result;
      lst.forEach((i) => {
        var item = variable.find((x) => x.key === i.name + "_" + i.type);
        if (item) {
          if (item.definition.includes(i.definition)) return;
          item.definition.push(i.definition);
        } else {
          variable.push({
            key: i.name + "_" + i.type,
            name: i.name,
            value: "",
            definition: [i.definition],
            type: i.type,
          });
        }
      });
      setVariable([...variable]);
    });
  }

  function checkSave() {
    return item.name &&
      item.image &&
      item.location &&
      item.tags &&
      item.tags?.length > 0 &&
      item.pColor &&
      item.sColor &&
      item.tColor &&
      item.fColor &&
      !loadingBtn &&
      !checkName() &&
      (variable.length === 0 ||
        variable.filter((x) => x.name).length === variable.length)
      ? false
      : true;
  }
  function SaveData() {
    setLoadingBtn(true);
    var Item = {
      ...item,
      variable: variable.map((x) => ({
        variable_id: Guid.newGuid().toString(),
        value: x.value ?? "",
        name: x.name,
        type: x.type,
      })),
    };
    API.brand
      .update({ data: Item })
      .then((x) => {
        notification.success({ message: "Success" });
        setVariable([]);
        setItem({
          pColor: "#1677ff",
          sColor: "#ddd",
          tColor: "#fff",
          fColor: "#000",
        });
      })
      .finally(() => setLoadingBtn(false));
  }

  function selectBrand(brand) {
    API.variable
      .getDefinition({ brand_id: brand.brand_id })
      .then((e) => {
        const uniqueItems = {};
        e.data.result.forEach((i) => {
          if (uniqueItems[i.name + "_" + i.type] === undefined)
            uniqueItems[i.name + "_" + i.type] = {
              ...i,
              definition: [i.definition],
              key: i.name + "_" + i.type,
            };
          else uniqueItems[i.name + "_" + i.type].definition.push(i.definition);
        });
        console.log(uniqueItems);
        setVariable(Object.values(uniqueItems));
        return API.brand.get({ id: brand.brand_id });
      })
      .then((x) => {
        setItem(x.data.result);
        setOpen(false);
      });
  }
  function checkName() {
    var a = brands.find(
      (x) => x.name === item.name && x.brand_id !== item.brand_id
    );
    return a ? "error" : "";
  }
  function showBlank() {
    const x = Modal.confirm({
      title: "Blank variables",
      footer: [
        <Button style={{ float: "right" }} onClick={() => x.destroy()}>
          Close
        </Button>,
      ],
      content: (
        <List
          style={{ maxHeight: 300, marginBottom: 16, overflowY: "auto" }}
          bordered
          dataSource={variable.filter((x) => !x.value)}
          renderItem={(i) => (
            <List.Item>
              {i.name} <span style={{ color: "#ccc" }}>({i.type})</span>
            </List.Item>
          )}
        />
      ),
    });
  }
  function showUnmatch() {
    const modal = Modal.confirm({
      title: "Unmatch variables",
      footer: [
        <Button style={{ float: "right" }} onClick={() => modal.destroy()}>
          Close
        </Button>,
      ],
      content: (
        <div style={{ textAlign: "center" }}>
          <span>
            <LoadingOutlined /> Loading
          </span>
        </div>
      ),
    });
    API.variable.getByTag({ tagId: item.tags }).then(({ data }) => {
      if (data) {
        var result = data.result;
        var dataSrc = [];
        variable.forEach((i) => {
          if (!result.find((ii) => ii.name === i.name)) {
            dataSrc.push(i);
          }
        });
        modal.update({
          content: (
            <List
              style={{ maxHeight: 300, marginBottom: 16, overflowY: "auto" }}
              bordered
              dataSource={dataSrc}
              renderItem={(i) => (
                <List.Item style={{ display: 'flex' }} >
                  {i.name}
                  <Typography.Text type="secondary">({i.type})</Typography.Text>

                  {/* <Button style={{marginLeft: 'auto'}} size="small" type="text" icon={<CloseCircleOutlined/>}/> */}
                </List.Item>
              )}
            />
          ),
        });
      }
    });
  }

  const handleImageLoad = () => {
    setLoadingImg(false);
  };

  return (
    <Form autoComplete="off" labelCol={{ span: 9 }} wrapperCol={{ span: 15 }}>
      <Button
        style={{ margin: "5px 16px" }}
        icon={<SwitcherOutlined />}
        onClick={() => setOpen(true)}
        type="default"
      >
        Change Brand
      </Button>
      <Border style={{ margin: 16 }} title="Edit">
        <Row gutter={16}>
          <Col xs={24} md={14}>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Brand Name" required>
                  <Input
                    status={checkName()}
                    value={item.name}
                    onInput={(e) => setItem({ ...item, name: e.target.value })}
                    placeholder="Input name"
                  />
                </Form.Item>
                <Form.Item label="Brand Logo" required>
                  <UploadImage
                    setImage={(data) => {
                      setItem({ ...item, image: data });
                    }}
                    setLoadingBtn={(child) => {
                      setLoadingBtn(child);
                    }}
                  >
                    <Center
                      className="effect"
                      direction="column"
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {item.image ? (
                        <img
                          alt=""
                          src={Src(item.image)}
                          style={{
                            height: "100px",
                            width: "100%",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <Center width={120} height={120}>
                          <PlusOutlined />
                          Upload
                        </Center>
                      )}
                    </Center>
                  </UploadImage>
                </Form.Item>
                <Form.Item label="Brand Location" required>
                  <Input
                    value={item.location}
                    onInput={(e) =>
                      setItem({ ...item, location: e.target.value })
                    }
                    placeholder="Input location"
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Primary Color">
                  <ColorPicker
                    style={{ width: 120, justifyContent: "left" }}
                    value={item.pColor}
                    onChange={(e) =>
                      setItem({ ...item, pColor: e.toHexString() })
                    }
                    showText
                  />
                </Form.Item>
                <Form.Item label="Secondary Color">
                  <ColorPicker
                    style={{ width: 120, justifyContent: "left" }}
                    value={item.sColor}
                    onChange={(e) =>
                      setItem({ ...item, sColor: e.toHexString() })
                    }
                    showText
                  />
                </Form.Item>
                <Form.Item label="Third Color">
                  <ColorPicker
                    style={{ width: 120, justifyContent: "left" }}
                    value={item.tColor}
                    onChange={(e) =>
                      setItem({ ...item, tColor: e.toHexString() })
                    }
                    showText
                  />
                </Form.Item>
                <Form.Item label="Fourth Color">
                  <ColorPicker
                    style={{ width: 120, justifyContent: "left" }}
                    value={item.fColor}
                    onChange={(e) =>
                      setItem({ ...item, fColor: e.toHexString() })
                    }
                    showText
                  />
                </Form.Item>
              </Col>
            </Row>
            <div>
              <Border.Divider />
              <Space>
                <Button
                  onClick={SaveData}
                  icon={<SaveOutlined />}
                  size="large"
                  disabled={checkSave()}
                  type="primary"
                >
                  Save Brand
                </Button>
              </Space>
            </div>
          </Col>
          <Col xs={24} md={10}>
            <Flex gap={4} style={{ width: "100%" }}>
              <span style={{ color: "red" }}>*</span>
              <Select
                mode="multiple"
                style={{ marginBottom: 16, flex: 1 }}
                onChange={(e) => {
                  item.tags = e;
                  setItem({ ...item });
                  PullVariable();
                }}
                allowClear
                value={item.tags}
                options={tag}
                placeholder="Select tag"
                showSearch
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
              <Button
                onClick={PullVariable}
                disabled={!item.tags}
                type="default"
              >
                Pull Variables
              </Button>
            </Flex>
            <Border title="Variable">
              <div>
                <div style={{ maxHeight: 300, overflowY: "auto" }}>
                  {variable.map((x) => (
                    <div
                      key={x.key}
                      style={{ display: "flex", gap: 8, marginBottom: 8 }}
                    >
                      <Tooltip title={Array.from(new Set(x.definition.filter((i) => i))).join(", ")}>
                        <Input
                          readOnly
                          value={x.name}
                          placeholder="Input name"
                          style={{ width: 150 }}
                        />
                      </Tooltip>
                      <Tooltip
                        title={
                          x.value?.includes("/images/") && (
                            <img
                              style={{
                                height: 100,
                                width: 100,
                                objectFit: "cover",
                              }}
                              alt=""
                              src={Src(x.value)}
                            />
                          )
                        }
                      >
                        <Input
                          disabled={x.type !== "text"}
                          allowClear
                          value={x.value}
                          onChange={(e) => {
                            setVariable(
                              variable.map((i) =>
                                i.key === x.key
                                  ? { ...i, value: e.target.value }
                                  : i
                              )
                            );
                          }}
                          placeholder="Input value"
                          style={{ flex: 7 }}
                        />
                      </Tooltip>
                      <UploadImage
                        setImage={(src) =>
                          setVariable(
                            variable.map((i) =>
                              i.key === x.key ? { ...i, value: src } : i
                            )
                          )
                        }
                        setLoadingBtn={(child) => {
                          setLoadingBtn(child);
                        }}
                      >
                        <Tooltip title="Upload">
                          <Button
                            disabled={x.type === "text"}
                            icon={<UploadOutlined />}
                          />
                        </Tooltip>

                      </UploadImage>
                      <Tooltip title="Remove">
                        <Button
                          onClick={() =>
                            setVariable(variable.filter((i) => x.key !== i.key))
                          }
                          danger
                          icon={<DeleteOutline />}
                        />
                      </Tooltip>
                    </div>
                  ))}
                </div>
                {variable?.length === 0 ? <Empty /> : null}
                {/* <Button
                  block
                  disabled={!item.tags}
                  onClick={AddVariable}
                  type="dashed"
                  icon={<PlusOutlined />}
                >
                  Add variable
                </Button> */}
              </div>
            </Border>
            <Space
              style={{
                marginTop: 8,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button onClick={showBlank}>Show blank variables</Button>
              <Button onClick={showUnmatch}>Show unmatched variables</Button>
            </Space>
          </Col>
        </Row>
      </Border>
      <Modal
        footer={false}
        title="Search brand"
        open={open}
        onCancel={() => setOpen(false)}
      >
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          style={{ marginBottom: 4 }}
          allowClear
          prefix={<SearchOutlined />}
          placeholder="Search brand"
        />
        <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
          {loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Spin size="large" />
              Loading...
            </div>
          ) : brands.filter((x) => (x.name + x.loaction).includes(search))
            .length > 0 ? (
            // brands
            //   .filter((x) => (x.name + x.loaction).toLowerCase().includes(search.toLowerCase()))
            //   .map((x) => (
            //     <div
            //       className="item-select"
            //       key={x.brand_id}
            //       onClick={() => selectBrand(x)}
            //     >
            //       <Spin spinning={loadingImg} size="small">
            //         <Avatar src={Src(x.image)} onLoad={handleImageLoad} />
            //       </Spin>
            //       <div>
            //         <strong style={{ display: "block" }}>{x.name}</strong>
            //         <span>Location: {x.location}</span>
            //       </div>
            //     </div>
            //   ))
            <div>
              <List
                itemLayout="horizontal"
                dataSource={
                  currentBrands
                }
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      onClick={() => selectBrand(item)}
                      style={{ cursor: "pointer" }}
                      avatar={
                        <Spin spinning={loadingImg} size="small">
                          <Avatar
                            src={Src(item.image)}
                            onLoad={handleImageLoad}
                          />
                        </Spin>
                      }
                      title={item.name}
                      description={<span>Location: {item.location}</span>}
                    />
                  </List.Item>
                )}
              />
              <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={
                  !search ? brands.length : handleSearchBrands(search).length
                }
                onChange={handlePageChange}
              />
            </div>
          ) : (
            <Empty />
          )}
        </div>
      </Modal>
    </Form>
  );
}
