import { Link, useNavigate, } from "react-router-dom"
import { useState, useRef } from "react";
import { toast } from "react-toastify";
import { useAuth } from "./../utils/auth"
import axios from './../configs'

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';

import Link1 from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ExternalLogin from "../components/ExternalLogin";
import { Divider } from "@mui/material";


const Login = () => {
  const navigate = useNavigate()
  const auth = useAuth()

  const [loginForm, setLoginform] = useState({
    username: "",
    password: "",
  });

  const onChangeForm = (label, event) => {
    switch (label) {
      case "username":
        setLoginform({ ...loginForm, username: event.target.value });
        break;
      case "password":
        setLoginform({ ...loginForm, password: event.target.value });
        break;
      default:
        break;
    }
  };

  const onSubmitHandler = async (event) => {

    event.preventDefault();

    await axios
      .post("/auth/login", loginForm)
      .then((response) => {

        //debugger;
        // Save token to local storage
        auth.login(response.data.result.access_token, response.data.result.token_type)

        //console.log("login response: ", response)
        // localStorage.setItem("user_information", response.config.data); //username, password

        toast.success("You have been logged in.")
      })
      .catch((error) => {
        // add error notif
        toast.error(error.response.data.detail);
        return navigate('/login')

      });
  };


  return (
    <>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: '#016c74' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          <ExternalLogin></ExternalLogin>
          <Divider style={{ width: '100%' }}>Or</Divider>
          <Box component="form" onSubmit={onSubmitHandler} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email"
              name="name"
              autoComplete="name"
              autoFocus
              onChange={(event) => {
                onChangeForm("username", event);
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(event) => {
                onChangeForm("password", event);
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Link1 href="/forgot" variant="body2">
                  Forgot password?
                </Link1>
              </Grid>
              <Grid item>
                <Link1 href="/register" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link1>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </>
  )
}

export default Login