import { useState } from 'react'
import { useNavigate } from "react-router-dom";
import React from 'react'
import { toast } from 'react-toastify';
import axios from './../configs'
import './register.css';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const SettingCompany = () => {
    const navigate = useNavigate();
    const auth_token = localStorage.getItem("auth_token");
    const auth_token_type = localStorage.getItem("auth_token_type");
    const token = auth_token_type + " " + auth_token;

    // Register Form
    const [formRegister, setFormRegister] = useState({
        //model_name: "",
        chain_type: "stuff",
        temperature: 0,
        verbose: 0,
        //top_k: 0,
        //top_p: 0,
        //is_tracing: "",
        //content_prompt_template: "",
        //qa_prompt_template: "",
        //max_tokens: 0,
        //frequency_penalty: 0,
        //presence_penalty: 0,
        is_streaming: 0,
        //batch_size: 0,
        //best_of: 0,
        // memory_key: "",
        //agent: "",
        //tools: "",
        default: "",
        // profile: ""
        model_name: ""
    })

    const onSubmitGetInfoComp = async () => {

        //fetch data from get user api
        axios
            .get("/setting/getCompany", { headers: { Authorization: token } })

            .then((response) => {

                setFormRegister({ ...response.data.result });

            })
            .catch(() => {
                // add error notif
            });
    };

    function ButtonCancle() {
        return navigate("/home");
    }

    React.useEffect(() => {
        onSubmitGetInfoComp();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onChangeForm = (label, event) => {
        switch (label) {
            case "chain_type":

                setFormRegister({ ...formRegister, chain_type: event.target.value });

                break;

            case "temperature":
                setFormRegister({ ...formRegister, temperature: event.target.value });
                break;
            case "verbose":
                setFormRegister({ ...formRegister, verbose: event.target.value });
                break;


            case "is_streaming":
                setFormRegister({ ...formRegister, is_streaming: event.target.value });
                break;

            case "model_name":
                setFormRegister({ ...formRegister, model_name: event.target.value });
                break;
            default:
                break;
        }

    };

    //   Submit handler
    const onSubmitHandler = async (event) => {
        event.preventDefault();
        // Post to register API
        await axios
            .post(`/setting/updateCompany`, formRegister, { headers: { Authorization: token } })
            .then((response) => {
                // move to sign in page
                navigate("/home");
                // add successfully notif/auth
                toast.success(response.data.detail);

            })
            .catch((error) => {
                // add error notif
                // toast.error(error.response.data.detail);
                toast.error(error.response);
            });
    };

    return (
        <div className='setting_container'>
            <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                <p className='login_header' style={{ padding: 0, margin: 0 }}>
                    Setting Company
                </p>

                <Divider style={{ marginLeft: 20, marginRight: 20, marginTop: 20 }} />
            </div>
            <form onSubmit={onSubmitHandler}>
                <div className='form_login' style={{ paddingLeft: 20, paddingRight: 20 }}>


                    <Accordion >
                        <AccordionDetails>
                            <Grid container spacing={2} columns={16}>
                                <Grid xs={8} style={{ paddingBottom: 10 }}>
                                    <label htmlFor="chain_type" className='font_text' style={{ paddingBottom: 10 }}>Chain Type</label>


                                    <TextField id="chain_type" style={{ width: '100%', backgroundColor: 'white' }}
                                        placeholder="" value={formRegister.chain_type}
                                        onChange={(event) => {
                                            onChangeForm("chain_type", event)
                                        }} />
                                </Grid>
                                <Grid xs={8} style={{ paddingBottom: 10 }}>
                                    <label htmlFor="verbose" className='font_text' style={{ paddingBottom: 10 }}>Verbose</label>


                                    <TextField id="verbose" style={{ width: '100%', backgroundColor: 'white' }}
                                        placeholder="" value={formRegister.verbose}
                                        onChange={(event) => {
                                            onChangeForm("verbose", event)
                                        }} />
                                </Grid>

                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion >
                        <AccordionDetails>
                            <Grid container spacing={2} columns={16}>
                                <Grid xs={8} style={{ paddingBottom: 10 }}>
                                    <label htmlFor="temperature" className='font_text' style={{ paddingBottom: 10 }}>Temperature</label>


                                    <TextField id="temperature" style={{ width: '100%', backgroundColor: 'white' }}
                                        placeholder="" value={formRegister.temperature}
                                        onChange={(event) => {
                                            onChangeForm("temperature", event)
                                        }} />

                                </Grid>


                                <Grid xs={8} style={{ paddingBottom: 10 }}>
                                    <label htmlFor="is_streaming" className='font_text' style={{ paddingBottom: 10 }}>Is_streaming</label>

                                    <TextField id="is_streaming" style={{ width: '100%', backgroundColor: 'white' }}
                                        placeholder="" value={formRegister.is_streaming}
                                        onChange={(event) => {
                                            onChangeForm("is_streaming", event)
                                        }} />

                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion >
                        <AccordionDetails>
                            <Grid container spacing={2} columns={16}>
                                <Grid xs={8} style={{ paddingBottom: 10 }}>
                                    <label htmlFor="model_name" className='font_text' style={{ paddingBottom: 10 }}>Model Name</label>
                                    <Select
                                        style={{ width: '100%' }}
                                        value={formRegister.model_name}
                                        onChange={(event) => { onChangeForm("model_name", event) }}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                        <MenuItem value={'gpt-3'}>GPT-3</MenuItem>
                                        <MenuItem value={'gpt-3.5'}>GPT-3.5</MenuItem>
                                        <MenuItem value={'gpt-4'}>GPT-4</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid xs={8}>
                                </Grid>

                            </Grid>
                        </AccordionDetails>
                    </Accordion>


                    <div style={{ paddingBottom: 20 }}>
                        <Button onClick={ButtonCancle} className='button_cancle' variant="contained" style={{ backgroundColor: 'red', margin: '20px 0px' }}
                        >
                            <div role="status">
                                <span>Cancel</span>
                            </div>
                        </Button>
                        <Button className='button_setting' variant="contained" style={{ backgroundColor: 'teal', margin: '20px' }}
                            type="submit">
                            <div role="status">
                                <span>Save</span>
                            </div>
                        </Button>



                    </div>
                </div>

            </form>
        </div>
    )
}

export default SettingCompany
