import React from "react";
import { Form, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import Home from "../pages/Home";

export default function Applications({ modal: [item, setModal], formAction, form }) {
   
    useEffect(() => {
   
    }, [])

    const gotoApp = (app)=>{
      setModal({
      open: false,
      title: <>Applications</>,
      footer: <></>
    })
    }

    return <Modal {...item} onCancel={() => setModal({})} forceRender>
        <Home home={true} gotoApp={gotoApp}></Home>
    </Modal>
}