import React, { createContext, useState } from "react";
import "./index.css";
import {
  createBrowserRouter,
  RouterProvider,
  Link,
  Navigate,
} from "react-router-dom";
import Register from "./pages/Register";
import Forgot from "./pages/Forgot";
import Login from "./pages/Login";
import Reset from "./pages/Reset";
import Setting from "./pages/Setting";
import { ToastContainer } from "react-toastify";
import "react-toastify/ReactToastify.min.css";
import "./App.css";
import MainLayout from "./components/MainLayout";
import LoginLayout from "./components/LoginLayout";
import ExternalLogin from "./components/ExternalLogin";
import UserManagement from "./pages/UserManagement";
import UsersManagementTabs from "./pages/UsersManagementTabs";
import UserDetail from "./pages/UserDetail";
import Authorized_Access from "./pages/AuthorizedAccess";
import ChangePassword from "./pages/ChangePassword";
import Migration from "./pages/Migration";
import Create from "./pages/Create";
import TemplateTabs from "./pages/TemplateTabs";
import BrandTab from "./pages/BrandTab";
import RoleManagement from "./pages/Users/RoleManagement";
import PermissionManagement from "./pages/Users/PermssionManagement";
import Help from "./pages/Help";
import About from "./pages/About";
import Home from "./pages/Home";
import { Button, Flex, Result } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import AdminPage from "./pages/AdminPage";
import SystemInfomation from "./pages/Users/SystemInfomation";

function ErrorPage() {
  return (
    <Flex vertical>
      <Result
        status="404"
        title="Not found."
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Link to="/">
            <Button type="primary" icon={<ArrowLeftOutlined />}>
              Back Home
            </Button>
          </Link>
        }
      />
    </Flex>
  );
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/opmanual" />,
  },
  {
    path: "/home",
    element: <Navigate to="/opmanual" />,
  },
  {
    path: "/login",
    element: (
      <LoginLayout>
        <Login />
      </LoginLayout>
    ),
  },
  {
    path: "/login-external",
    element: (
      <LoginLayout>
        <ExternalLogin></ExternalLogin>
      </LoginLayout>
    ),
  },
  {
    path: "/reset",
    element: (
      <LoginLayout>
        <Reset></Reset>
      </LoginLayout>
    ),
  },
  {
    path: "/register",
    element: (
      <LoginLayout>
        <Register />
      </LoginLayout>
    ),
  },
  {
    path: "/forgot",
    element: (
      <LoginLayout>
        <Forgot />
      </LoginLayout>
    ),
  },
  {
    path: "/opmanual",
    element: (
      <MainLayout>
        <div style={{ paddingTop: 32, textAlign: "center" }}>
          <Home></Home>
        </div>
      </MainLayout>
    ),
  },
  {
    path: "/opmanual/create",
    element: (
      <MainLayout>
        <Create />
      </MainLayout>
    ),
  },
  {
    path: "/opmanual/templates",
    element: (
      <MainLayout>
        <TemplateTabs />
      </MainLayout>
    ),
  },
  {
    path: "/opmanual/brands",
    element: (
      <MainLayout>
        <BrandTab />
      </MainLayout>
    ),
  },
  {
    path: "/opmanual/user-management-detail",
    element: (
      <MainLayout>
        <UsersManagementTabs />
      </MainLayout>
    ),
  },
  {
    path: "/opmanual/user-management",
    element: (
      <MainLayout>
        <UserManagement />
      </MainLayout>
    ),
  },
  {
    path: "/opmanual/role-management",
    element: (
      <MainLayout>
        <RoleManagement />
      </MainLayout>
    ),
  },
  {
    path: "/opmanual/permission-management",
    element: (
      <MainLayout>
        <PermissionManagement />
      </MainLayout>
    ),
  },
  {
    path: "/opmanual/access-log",
    element: (
      <MainLayout>
        <Authorized_Access />
      </MainLayout>
    ),
  },
  {
    path: "/opmanual/system_info",
    element: (
      <MainLayout>
        <SystemInfomation />
      </MainLayout>
    ),
  },
  {
    path: "/help",
    element: (
      <MainLayout>
        <Help />
      </MainLayout>
    ),
  },
  {
    path: "/about",
    element: (
      <MainLayout>
        <About />
      </MainLayout>
    ),
  },

  {
    path: "/setting",
    element: (
      <MainLayout>
        <Setting />
      </MainLayout>
    ),
  },
  {
    path: "/opmanual/administrator",
    element: (
      <MainLayout>
        <AdminPage />
      </MainLayout>
    ),
  },
  {
    path: "*",
    element: (
      <MainLayout>
        <ErrorPage />
      </MainLayout>
    ),
  },
  {
    path: "/user-detail",
    element: (
      <MainLayout>
        <UserDetail />
      </MainLayout>
    ),
  },
  // {
  //   path: "/update-role-user",
  //   element: (
  //     <MainLayout>
  //       <UpdateRoleUser />
  //     </MainLayout>
  //   ),
  // },
  {
    path: "/change-password",
    element: (
      <MainLayout>
        <ChangePassword />
      </MainLayout>
    ),
  },
  {
    path: "/migrate",
    element: (
      <MainLayout>
        <Migration />
      </MainLayout>
    ),
  },
]);

const Context = createContext();

function App({ children }) {
  const item = useState({
    content: "",
    tags: [],
    section: "",
    sectionTitle: "",
    titleConfig: {
      font: 'Tahoma',
      fontSize: '12pt'
    }
  });
  const variable = useState([]);
  const varSrc = useState([]);
  const varTitle = useState([]);
  const isWhen = useState(false);
  const saved = useState(true);
  return (
    <Context.Provider value={{ item, variable, varSrc, varTitle, isWhen, saved }}>
      <ToastContainer />
      <RouterProvider router={router} />
    </Context.Provider>
  );
}

export default App;
export { Context };
