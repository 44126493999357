import React, { useState } from "react";
import { AuthProvider, useAuth } from "../utils/auth";
import { RequireAuth } from "../utils/RequireAuth";
import { useAppStore } from "../utils/appstate";
import Utils from "../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios, { API } from './../configs'
import { useIdleTimer } from 'react-idle-timer/legacy'
import { CopyrightCircleOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, ConfigProvider, Popover, Space, Tag } from 'antd';
import { Layout, Menu, theme } from 'antd';
import Profile from '../pages/Profile';
import Applications from "./Applications";

const { Header, Content, Footer } = Layout;

function MainLayout({ children }) {
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const path = useLocation().pathname
  const appInfo = useAppStore((state) => state.AppInfo);
  const navigate = useNavigate();
  const updateAppInfo = useAppStore((state) => state.updateAppInfo);
  const [menus, setMenus] = useState([]);
  const auth = useAuth();
  const maxNested = 5
  // const idleTimerRef = React.useRef(null);
  const [modalProfie, setModalProfile] = useState({})
  const logout = () => {
    localStorage.clear("auth_token", "auth_token_type");
    toast.success("You have been logged out.");
    navigate("/login");
  }

  let user = Utils.getUserInfo();

  function buildCurrentSection(sections, rootSection, currentMaxNested) {
    if (!rootSection.id) {
      return;
    }
    currentMaxNested += 1;
    if (currentMaxNested > maxNested) {
      return rootSection;
    }

    let childrenOfRoot = sections.filter(t => t.parent_menu_id === rootSection.id);
    if (childrenOfRoot.length === 0) {
      return rootSection;
    }
    childrenOfRoot.forEach((child, index) => {
      child.key = child.id;
      child.label = child.menu_name;
      buildCurrentSection(sections, child, currentMaxNested);
    });
    childrenOfRoot.sort((a, b) => {
      if (a.sort < b.sort) { return -1; }
      if (a.sort > b.sort) { return 1; }
      return 0;
    });
    rootSection.children = childrenOfRoot;
  }

  const onAction = (event, idleTimer) => {
    // if (idleTimer.isPrompted()) {
    //   idleTimer.activate()
    // }
  }

  const onActive = (event, idleTimer) => {
    // if (idleTimer.isPrompted()) {
    //   setOpen(false)
    // }
  }

  const onPrompt = () => {
    // setOpen(true)
  }

  const onIdle = () => {
    logout()
  }

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    promptBeforeIdle: 0, //1000 * 30,
    onAction,
    onActive,
    onPrompt,
    onIdle
  });

  React.useEffect(() => {
    const getMenus = async () => {
      // axios.get(`/users/get-menu-permission`, {
      //      headers: { Authorization: Utils.getToken() },
      //    })
      API.menu.getMenu()
        .then((response) => {
          let menus = response.data.result;
          setMenus(response.data.result);
          var groupBy = function (xs, key) {
            return xs.reduce(function (rv, x) {
              (rv[x[key]] = rv[x[key]] || []).push(x);
              return rv;
            }, {});
          };


          let newInfo = { ...appInfo };

          newInfo.Menus = []; //rootMenus;

          let items = groupBy(menus, "app_id");

          let apps = []
          for (let key in items) {
            // console.log(key, items[key]);
            apps.push({
              app_id: items[key][0].app_id,
              app_code: items[key][0].app_code,
              app_name: items[key][0].app_name,
              app_sort: items[key][0].app_sort,
              children: items[key],
              icon_app: items[key][0].icon_app
            });
          }
          apps.sort(function (a, b) { return a.app_sort - b.app_sort });
          // console.log(apps)

          let url = menus.find(x => x.menu_url === path);

          apps.forEach(app => {
            let childs = app.children;
            let rootMenus = app.children.filter(t => t.parent_menu_id === "");
            // console.log(rootMenus)
            rootMenus.sort((a, b) => {
              if (a.sort < b.sort) { return -1; }
              if (a.sort > b.sort) { return 1; }
              return 0;
            });

            rootMenus.forEach((node) => {
              let curNested = 0;
              node.key = node.id;
              node.label = node.menu_name;
              buildCurrentSection(childs, node, curNested);
            });

            app.children = rootMenus;
            if (url && app.app_id === url.app_id) {
              newInfo.Menus = app.children;
              newInfo.currentMenuId = url.id;
              setCurrent(url.id)
            }

          })

          newInfo.Apps = apps;

          newInfo.user = user;


          newInfo.IsReady = true;

          updateAppInfo(newInfo);
          toast.success(response.data.result);
        })
        .catch((error) => {
          navigate("/login");
        });
    };
    //  console.log(path);
    getMenus();
    showMyProfile();
  }, []);

  const onClick = (e) => {
    let navMenu = menus.find(t => t.id === e.key);
    if (navMenu)
      navigate(navMenu.menu_url);
    else navigate(e.key)
    setCurrent(e.key)
    appInfo.currentMenuId = e.key;
  };

  const [current, setCurrent] = useState('');

  const onSubmitHandlerLogout = async (event) => {
    let user = Utils.parseJwt(Utils.getToken());
    //event.preventDefault();
    let userData = {
      username: user.username,
      password: ""
    }
    // console.log("user", userData);
    await axios
      .post("/auth/logout", userData)
      .then((response) => {

        //debugger;
        // Save token to local storage
        localStorage.clear("auth_token", "auth_token_type");
        toast.success("You have been logged out.");
        navigate("/login");
      })
      .catch((error) => {
        // add error notif
        localStorage.clear("auth_token", "auth_token_type");
        toast.success("You have been logged out.");
        navigate("/login");
        toast.error(error.response.data.detail);
      });
  };
  const [openApplicaion, setOpenApplicaion] = useState({})
  const openApplicationDialog = () => {
    setOpenApplicaion({
      open: true,
      title: <>Applications</>,
      footer: <></>
    })
  }

  const showMyProfile = async (event) => {
    let user = Utils.parseJwt(Utils.getToken());
    // console.log("user.id", user.id);
    let id = user.id;
    // await axios
    //        .get(`/menus/getprofile`, {params: { id: id } }   
    API.menu.getMyProfile({ id })
      .then((response) => {
        // console.log("response.data.result",response.data.result);
        //setFormRegister({ ...response.data.result });
        let dataResult = response.data.result;
        setModalProfile(x => ({
          ...x, data: {
            User: dataResult.name, Email: dataResult.email,
            "List role": dataResult.list_role_for_user.map(x => <Tag key={x.roleid}>{x.role_name}</Tag>)
          }
        }))
      })
      .catch((error) => {
        // add error notif            
        toast.error(error.message);

      });
  }




  const openProfileModal = () => {


    setModalProfile({

      open: true,
      title: <>My Profile</>,
      data: modalProfie.data

    })

  }

  return (

    <AuthProvider>
      <ConfigProvider theme={{ token: { colorPrimary: '#27aae1' } }}>
        <Applications modal={[openApplicaion, setOpenApplicaion]} />
        <Layout className="layout" style={{ height: '100vh' }}>
          <Header style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
            {/* <Button onClick={openApplicationDialog} icon={<MenuOutlined />}></Button> */}
            <img onClick={openApplicationDialog} src='/static/background-main.jpg' alt='' style={{ height: '60%', borderRadius: 4 }} />
            <Menu style={{ flex: 1 }}
              theme="dark"
              mode="horizontal"
              onClick={onClick}
              inlineIndent={3}
              selectedKeys={[appInfo.currentMenuId]}
              items={[...appInfo.Menus, { key: '/help', label: 'Help' }, { key: '/about', label: 'About' }]}
            />
            <Popover placement="bottomRight" content={<Space direction="vertical">

              <Button block onClick={openProfileModal} type="text" icon={<UserOutlined />}>My Profile</Button>
              <Button block danger onClick={onSubmitHandlerLogout} type="text" icon={<LogoutOutlined />}>Logout</Button>
            </Space>}>
              <Avatar style={{ background: '#555' }} >{appInfo?.user?.name?.at(0) ?? <UserOutlined />}</Avatar>
            </Popover>
            <Profile modal={[modalProfie, setModalProfile]} />
          </Header>
          <Content>
            <div className="site-layout-content" style={{ background: colorBgContainer }}>
              {appInfo.IsReady && <RequireAuth>{children}</RequireAuth>}
            </div>
          </Content>
          <Footer style={{ textAlign: 'center', padding: 8 }}>SMBFranchising <CopyrightCircleOutlined /> 2023, Authorized users only.</Footer>
        </Layout>
      </ConfigProvider>
    </AuthProvider>

  );


}

export default MainLayout;
