import { Badge, Card, Empty, Flex, Input, Modal, Select } from "antd";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios, { auth } from "../configs";
import {  CaretRightOutlined,  SearchOutlined } from "@ant-design/icons";
import HighText from "./HL";
const ShowButton = ({show})=> <div className={(show?'show ': '') +"show-btn"}><CaretRightOutlined /></div>

const Render = ({ data, onClick }) => {
  const [show, setShow] = useState([]);
  const toggle = (id) =>{
    let index = show.indexOf(id)
    if(index === -1){
      setShow([...show, id])
    }else{
      setShow(show.filter(x=>x!==id))
    }
  }
  return data.map(i=><React.Fragment key={i.key} >
    <Card size="small" hoverable className="outline-item">
      <Flex>
        <Flex className="text-view" flex={1} onClick={()=>onClick(i.key, data.map(x=>x.key.split('___').at(-1)))}>
          {i.title}
        </Flex>
        {i.children.length > 0?<div className="btn-view" onClick={()=>toggle(i.key)} >
          <ShowButton show={show.includes(i.key)}/>
        </div>:null}
      </Flex>
    </Card>
    {show.includes(i.key) ?
      <div style={{ marginLeft: 32, display: 'flex', flexDirection: 'column', gap: 4 }}>
        <Render onClick={onClick} data={i.children} />
      </div> : null}
  </React.Fragment>)
}

const renderDataTree = (dataDefault = [], search, ref = '') => {

  let min = Math.min(...dataDefault.map(x => x.name.split('.').length))

  var rs = dataDefault.filter(i => i.name.split('.').length === min)
    .sort((a,b)=>parseInt(a.name.split('.').at(-1)) > parseInt(b.name.split('.').at(-1)) ? 1: -1)
    .map(i => ({
      key: ref +'___'+ i.section_id,
      title: <Badge status={i.isdeleted?"error":"success"} text={<HighText deleted={i.isdeleted} search={search} children={`section ${i.name}: ${i.title}`}/>}/>,
      children: renderDataTree(dataDefault.filter(ii => ii.name.startsWith(i.name+'.')), search, ref +'___'+ i.section_id)
    }))

  return rs;
}

export { renderDataTree }
export default function ShowOutlineEdit({ open, setOpen, setGuid, tag, setSrc }) {
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [selectTag, setselectTag] = useState([]);

  useEffect(() => {
    setSearch('')
    if (open === true && selectTag) {
      axios
        .get("/sections/get-section", auth({ IndustryId: JSON.stringify(selectTag) }))
        .then((rs) => {
          var dataTmp = rs.data.result
          setData(dataTmp);
        });
    }
  }, [selectTag, open]);
  const selectSection = (key, src) => {
    var section_id = key.split('___').at(-1)
    setGuid(section_id);
    setOpen(false);
    setSrc(src)
  }


  return (
    <Modal
      width={800}
      footer={false}
      open={open}
      title="Search section"
      onCancel={() => setOpen(false)}
    >
      <Select style={{ display: 'block', marginBottom: 16 }}
        mode="multiple"
        onChange={(e) => {
          setselectTag(e);
        }}
        value={selectTag}
        options={tag}
        placeholder="Select tag"
        showSearch
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
      <Input style={{ marginBottom: 10 }}
        value={search}
        onInput={(e) => setSearch(e.target.value)}
        placeholder="Search"
        prefix={<SearchOutlined />}
      />
      <div style={{ display: "flex", flexDirection: "column", gap: 4, maxHeight: '50vh', overflowY: 'auto', padding: '5px .5rem' }}>
        {data.length > 0 ? (
          // <Tree onClick={selectSection} switcherIcon={<DownOutlined />}
          //   treeData={renderDataTree(data.filter(i => `section ${i.name}: ${i.title}`.toLowerCase().includes(search.toLowerCase())), search, '')} />
          // <Render search={search} onClick={selectSection} data={data.filter(x => x.section?.split(".").length === 1)} dataAll={data} />
          <Render onClick={selectSection} data={renderDataTree(data.filter(i => `section ${i.name}: ${i.title}`.toLowerCase().includes(search.toLowerCase()) && i.isdeleted !== true), search, '')} />
        ) : (
          <Empty />
        )}
      </div>
    </Modal>
  );
}
