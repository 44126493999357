import { Form, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { API } from "../../../configs";

export default function PermissionModal({ modal: [item, setModal], formAction, form }) {
    const [menu, setMenu] = useState([])
    useEffect(() => {
        API.menu.getAll().then(({ data }) => {
            if (data) {
                setMenu(data.result)
            }
        })
    }, [])
    return <Modal {...item} onCancel={() => setModal({})} forceRender onOk={() => form.submit()} okButtonProps={{ loading: item.status, icon: item.icon }}>
        <Form autoComplete="off" form={form} onFinish={formAction} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
            <Form.Item name="id" hidden><Input /></Form.Item>
            <Form.Item name="name" label="Permission" required rules={[{ required: true, message: 'Permission is required' }]}>
                <Input />
            </Form.Item>
            <Form.Item name="menu_id" label="Menu" required rules={[{ required: true, message: 'Menu is required' }]}>
                <Select showSearch filterOption={(input, option) => (option?.search ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={menu.map(x=>({label: x.menu_name, search: x.menu_name, value: x.id}))} />
            </Form.Item>
        </Form>
    </Modal>
}