import { Button, InputNumber, Modal, message } from "antd";
import React, { useCallback, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

const ModalConfig = () => {
  const [open, setOpen] = useState(false);
  const [data, _setData] = useState({});

  window.modal = {
    ...window.modal,
    setOpen,
    setData: _setData,
    data: data,
  };

  const setData = (name, value) => _setData({ ...data, [name]: value });

  const handleSave = () => {
    if (data.content === "") {
      message.error("Content is required");
      return;
    }

    if (typeof window.modal.onSave == "function") window.modal.onSave(data);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      title={"Config shape"}
      footer={null}
      onCancel={() => setOpen(false)}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        <Editor
          value={data.content}
          onEditorChange={(text) => setData("content", text)}
          tinymceScriptSrc="/static/tinymce/tinymce.min.js"
          init={{
            toolbar:
              "undo redo | bold italic underline forecolor backcolor alignleft aligncenter alignright ",
            height: 200,
            menubar: false,
            statusbar: false,
            placeholder: "Type here...",
            content_style: "*{padding:0;margin:0}",
          }}
        />
        <div style={{ display: "flex", gap: 16 }}>
          <InputNumber
            value={data.width}
            onChange={(value) => setData("width", value)}
            placeholder="auto"
            addonAfter="px"
            addonBefore="Width:"
          />
          <InputNumber
            value={data.height}
            onChange={(value) => setData("height", value)}
            placeholder="auto"
            addonAfter="px"
            addonBefore="Height:"
          />
        </div>
        <div style={{ display: "flex", justifyContent: "end", gap: 8 }}>
          <Button
            danger
            onClick={() => {
              Modal.confirm({
                title: "Are you sure? Delete this item?",
                onOk: () => {
                  if (typeof window.modal.onDelete == "function")
                    window.modal.onDelete();
                  setOpen(false);
                },
              });
            }}
          >
            Delete Item
          </Button>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default ModalConfig;
