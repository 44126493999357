import {
  Button,
  Col,
  Collapse,
  Flex,
  Form,
  Input,
  Modal,
  Popconfirm,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  notification,
} from "antd";
import { useCallback, useEffect, useRef } from "react";
import Border from "../../components/Border";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  LeftOutlined,
  LoadingOutlined,
  MinusOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import ShowOutline from "../../components/ShowOutline";
import { Guid } from "js-guid";
import { Editor } from "@tinymce/tinymce-react";
import axios, { API, auth } from "../../configs";
import ShowOutlineEdit from "../../components/ShowOutlineEdit";
import { configTiny } from "../TemplateTabs";
import ModalConfig from "./ModalConfig";
import { useNavigate } from "react-router-dom";
import { updatePosition } from "./Template";

const Status = {
  start: null,
  error: (
    <Tag color="error">
      <CloseCircleOutlined /> Failed Loading
    </Tag>
  ),
  loading: (
    <Tag color="warning">
      <LoadingOutlined /> Not yet saved...
    </Tag>
  ),
  saving: (
    <Tag color="warning">
      <LoadingOutlined /> Saving
    </Tag>
  ),
  success: (
    <Tag color="success">
      <CheckCircleOutlined /> Saved
    </Tag>
  ),
};
const { Panel } = Collapse;


export default function TemplateEdit({ tab }) {
  const [variable, setVariable] = useState([]);
  const [varSrc, setVarSrc] = useState([]);
  const [isWhen, setIsWhen] = useState(false);
  const [openShowOutline, setOpenShowOutline] = useState(false);
  const [openShowOutline1, setOpenShowOutline1] = useState(false);
  const [item, setItem] = useState({});
  const [tag, setTag] = useState();
  const [status, setStatus] = useState("start");
  const [id, setId] = useState();
  const [src, setSrc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingE, setLoadingE] = useState(true);
  const [isdeleted, setisdeleted] = useState(false);
  const [update, setUpdate] = useState(false);
  const editorRef = useRef(null);
  const navi = useNavigate();
  const fontSize = configTiny.font_size_formats.split(" ");
  const font_family_formats = configTiny.font_family_formats.split(";");
  function add() {
    setVariable([
      ...variable,
      { key: Guid.newGuid().toString(), operator: "=" },
    ]);
  }
  function remove({ key }) {
    setVariable((v) => v.filter((x) => x.key !== key));
  }
  const checkSaveBtn = useCallback(() => {
    return (
      (item.tags?.length &&
      item.section &&
      loading === false &&
      item.sectionTitle &&
      (isWhen === false ||
        variable.filter((x) => x.name && x.value).length === variable.length)
        ? false
        : true) || !(/^\d+(\.\d+)*$/g.test(item.section) || !item.section)
    );
  }, [
    isWhen,
    item.section,
    item.sectionTitle,
    item.tags?.length,
    loading,
    variable,
  ]);

  useEffect(() => {
    API.tags
      .getAll()
      .then((response) => {
        setTag(
          response.data.result.map((x) => ({
            label: x.name,
            value: x.industry_id,
          }))
        );
      })
      .catch(() => {
        setTag([]);
      });
  }, [tab]);

  function resetData() {
    editorRef.current?.setContent("");
    setIsWhen(false);
    setId();
    setItem({
      tags: [],
      section: "",
      sectionTitle: "",
      content: "",
      titleConfig: {
        font: "Tahoma",
        fontSize: "12pt",
      },
    });
  }
  useEffect(() => {
    if (tab === "1") resetData();
  }, [tab]);
  function getListVariable(
    content,
    regex_get_value = /\{\{[\w| |&nbsp;]+\}\}/gm,
    remove = ["{{", "}}"]
  ) {
    let item = [],
      src = [];
    while ((item = regex_get_value.exec(content)) !== null) {
      let name = item[0];
      remove.forEach((item) => {
        name = name.replaceAll(item, "");
      });
      if (!src.includes(name)) {
        src.push(name);
      }
    }

    return src;
  }

  const getVarNotImage = useCallback((content) => {
    var src = getListVariable(content);
    var lst = getListVariable(content, /src="\{\{[\w| |&nbsp;]+\}\}"/gm, [
      'src="{{',
      '}}"',
    ]);
    var rs = src.filter((x) => !lst.includes(x));
    return rs;
  }, []);

  const handleBlur = useCallback(() => {
    let var_render = [];

    /**@type {Document}*/ let doc = editorRef.current.getDoc();
    let src = document.createElement("div");
    src.innerHTML = Array.from(doc?.querySelectorAll("span.callout")).reduce(
      (str, e) => (str += e.getAttribute("data-html-src")),
      ""
    );

    let title = item.sectionTitle;
    let varText = Array.from(doc?.querySelectorAll("i"))
      .map((x) => x.innerText)
      .concat([...title.matchAll(/{{(.*?)}}/g)].map((x) => x[1]))
      .concat(Array.from(src?.querySelectorAll("i")).map((x) => x.innerText));

    var_render = [...new Set(varText)];

    setVarSrc(var_render);
    setVariable((variable) =>
      variable.filter((x) => var_render.includes(x.name))
    );
  }, [item.sectionTitle, setVarSrc, setVariable]);

  function title() {
    if (id && item.section && item.sectionTitle)
      return `Section ${item.section}: ${item.sectionTitle}`;
    return "Loading...";
  }

  const SaveSection = useCallback(
    (d) => {

      updatePosition(editorRef.current.getDoc())

      var lst = Array.from(
        editorRef.current.getDoc()?.querySelectorAll('img[src^="{{"][src$="}}"]')
      ).map((/**@type {HTMLImageElement}*/ x) =>
        x.getAttribute("data-mce-src").replace(/^{{/, "").replace(/}}$/, "")
      );

      editorRef.current.getDoc()?.querySelectorAll("span.callout").forEach((e) => {
        let div = document.createElement("div");
        div.innerHTML = e.getAttribute("data-html-src");
        lst = lst.concat(Array.from(
          div?.querySelectorAll('img[src^="{{"][src$="}}"]')
        ).map((/**@type {HTMLImageElement}*/ x) =>
          x.getAttribute("src").replace(/^{{/, "").replace(/}}$/, "")
        ))
      })


      var ItemData = {
        section_id: id,
        industry_id: item?.tags,
        isdeleted: d ? !isdeleted : isdeleted,
        name: item?.section,
        title: item?.sectionTitle,
        title_config: JSON.stringify(item.titleConfig),
        detail: editorRef.current.getContent() ?? "",
        variable: varSrc
          .map((x) => ({
            variable_id: Guid.newGuid().toString(),
            name: x,
            type: "text",
          }))
          .concat(
            ...lst.map((i) => ({
              variable_id: Guid.newGuid().toString(),
              name: i,
              type: "image",
            }))
          ),
        variableData:
          isWhen === false
            ? []
            : variable.map((x) => ({
                variable_id: x.key,
                name: x.name,
                operator: x.operator,
                value: x.value ?? "",
                definition: x.definition ?? "",
              })),
      };
      setStatus("saving");
      return API.section.update({ data: ItemData }).then(() => {
        setStatus("success");
        if (timer.current) clearTimeout(timer.current);
      });
    },
    [
      id,
      isWhen,
      isdeleted,
      item?.section,
      item?.sectionTitle,
      item?.tags,
      item.titleConfig,
      varSrc,
      variable,
    ]
  );
  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .get("/sections/get-section-by-id", auth({ Id: id }))
        .then((x) => {
          let tmp = x.data.result;
          var content = tmp.detail;

          item.tags = tmp.industry_id;
          item.section = tmp.name;
          item.sectionTitle = tmp.title;
          item.titleConfig = JSON.parse(
            tmp.title_config ?? `{"font":"Tahoma","fontSize":"12pt"}`
          );

          setisdeleted(tmp.isdeleted);
          editorRef.current.setContent(content);
          editorRef.current.undoManager.reset();
          setUpdate(true);
          return API.section.variable({ section_id: id }).then((x) => {
            setIsWhen(x.data.result.length > 0);
            setVariable(
              x.data.result.map((x) => ({
                key: x.variable_id,
                name: x.name,
                operator: x.operator,
                value: x.value ?? "",
                definition: x.definition ?? "",
              }))
            );
          });
        })
        .finally(() => {
          setLoading(false);
          setStatus(0);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getVarNotImage, id, tab]);

  const timer = useRef();

  useEffect(() => {
    setStatus((x) => {
      if (x === 0) return "success";
      return "loading";
    });
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (checkSaveBtn()) {
        setStatus("error");
      } else {
        SaveSection();
      }
    }, 30000);
    return () => clearTimeout(timer.current);
  }, [SaveSection, checkSaveBtn]);

  useEffect(() => {
    if (loadingE === false) {
      let id_url = new URLSearchParams(window.location.search).get("id");
      if (id_url) {
        setId(id_url);
        setOpenShowOutline(false);
      } else {
        setOpenShowOutline(true);
      }
    }
  }, [loadingE]);

  function changeStatus() {
    Modal.confirm({
      title: "Do you really want to delete",
      okButtonProps: { danger: true },
      okText: "Delete",
      onOk: () => {
        setLoading(true);
        SaveSection(true).finally(() => {
          setLoading(false);
          resetData();
          notification.success({ message: "Deleted successfully." });
          setOpenShowOutline(true);
        });
      },
    });
  }

  function PreviousAction() {
    setId(src[src.findIndex((x) => x === id) - 1]);
  }
  function NextAction() {
    setId(src[src.findIndex((x) => x === id) + 1]);
  }

  useEffect(() => {
    if (editorRef.current) {
      if (item.section) {
        let count = item.section.split(".").length;
        if (count > 6) count = 6;
        editorRef.current.dom.doc.body.style.setProperty("--indent", count);
      } else editorRef.current.dom.doc.body.style.setProperty("--indent", 0);
    }
  }, [item.section]);

  useEffect(() => {
    if (update === true)
      setUpdate(() => {
        handleBlur();
        return false;
      });
  }, [update]);

  return (
    <div
      style={{
        margin: "0 auto",
        maxWidth: 1024,
        ...(loadingE && { opacity: 0.5, pointerEvents: "none" }),
      }}
    >
      <ModalConfig />
      <Flex gap={16} align="center">
        <h3>Edit template</h3>
        {id ? (
          <Space size={1}>
            <Tag
              onClick={changeStatus}
              style={{ cursor: "pointer" }}
              color="red"
            >
              <DeleteOutlined /> Delete
            </Tag>
            {Status[status]}
          </Space>
        ) : null}
        <Space style={{ marginLeft: "auto" }}>
          <Button
            onClick={PreviousAction}
            type="text"
            disabled={
              status !== "success" ||
              loading ||
              src.findIndex((x) => x === id) === 0
            }
          >
            <LeftOutlined /> Previous
          </Button>
          <Button
            onClick={NextAction}
            type="text"
            disabled={
              status !== "success" ||
              loading ||
              src.findIndex((x) => x === id) === src.length - 1
            }
          >
            Next <RightOutlined />
          </Button>
          <Button
            disabled={checkSaveBtn()}
            type="primary"
            onClick={() =>
              SaveSection()?.then?.(() => {
                notification.success({ message: "Success" });
              })
            }
          >
            Save Section
          </Button>
        </Space>
      </Flex>
      <Collapse defaultActiveKey={["1"]}>
        <Panel header={<b>Edit</b>} key="1">
          <Row gutter={16}>
            <Col xs={24} className="full">
              <Tooltip placement="topLeft" title="Select section">
                <Input
                  size="large"
                  onClick={() => setOpenShowOutline(true)}
                  value={title()}
                  readOnly
                  placeholder="Please select"
                  style={{ marginBottom: 16 }}
                />
              </Tooltip>
              <Form disabled={!id || loading}>
                <div style={{ display: "flex", gap: 16 }}>
                  <Form.Item
                    style={{ flex: 1 }}
                    label="Tags"
                    labelCol={{ style: { width: 70 } }}
                    required
                  >
                    <Select
                      allowClear
                      mode="multiple"
                      onChange={(e) => {
                        item.tags = e;
                        setItem({ ...item });
                      }}
                      value={item.tags}
                      options={tag}
                      placeholder="Select tag"
                      showSearch
                      size="large"
                      filterOption={(input, option) =>
                        option.label
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    />
                  </Form.Item>
                  <Button
                    size="large"
                    disabled={!item.tags?.length}
                    onClick={() => setOpenShowOutline(true)}
                  >
                    Show Outline
                  </Button>
                </div>

                <div style={{ display: "flex", gap: 8 }}>
                  <Form.Item label="Section" required>
                    <Input
                      status={
                        /^\d+(\.\d+)*$/g.test(item.section) || !item.section
                          ? "success"
                          : "error"
                      }
                      onInput={(e) => {
                        item.section = e.target.value;
                        setItem({ ...item });
                      }}
                      size="large"
                      value={item.section}
                      style={{ width: 100 }}
                    />
                  </Form.Item>
                  <Form.Item style={{ flex: 1 }} label="Section Title" required>
                    <Flex vertical gap={8}>
                      <Input
                        onChange={(e) => {
                          item.sectionTitle = e.target.value;
                          setItem({ ...item });
                        }}
                        onBlur={() => setUpdate(true)}
                        size="large"
                        value={item.sectionTitle}
                        placeholder="Enter section title"
                      />
                      <Flex gap={8}>
                        <Select
                          value={item.titleConfig?.font}
                          onChange={(_, change) => {
                            item.titleConfig.font = change.value;
                            setItem({ ...item });
                          }}
                          style={{ flex: 3 }}
                          placeholder="Select font"
                          options={font_family_formats.map((e) => {
                            let [title, value] = e.split("=");
                            return {
                              label: (
                                <span style={{ fontFamily: value }}>
                                  {title}
                                </span>
                              ),
                              value,
                            };
                          })}
                        />
                        <Select
                          value={item.titleConfig?.fontSize}
                          onChange={(_, change) => {
                            item.titleConfig.fontSize = change.value;
                            setItem({ ...item });
                          }}
                          s
                          style={{ flex: 2 }}
                          placeholder="Select font size"
                          options={fontSize.map((e) => {
                            return {
                              label: e,
                              value: e,
                            };
                          })}
                        />
                      </Flex>
                    </Flex>
                  </Form.Item>
                </div>
                <Flex gap={8} wrap="wrap">
                  <span>Section used when: </span>
                  <Space
                    style={{ marginBottom: 16, flex: 1 }}
                    direction="vertical"
                  >
                    <Radio.Group
                      value={!isWhen ? "all" : "when"}
                      direction="vertical"
                      onChange={(f) => {
                        setIsWhen(f.target.value === "all" ? false : true);
                      }}
                    >
                      <Space direction="vertical">
                        <Radio value="all">All the time</Radio>
                        <Radio value="when">When this variable</Radio>
                      </Space>
                    </Radio.Group>
                    <Space
                      direction="vertical"
                      style={{
                        overflowY: "auto",
                        maxHeight: "170px",
                        width: "100%",
                        alignItems: "end",
                      }}
                    >
                      {isWhen ? (
                        <>
                          {variable.map((x, i) => (
                            <Space.Compact
                              key={x.key}
                              size="middle"
                              style={{ alignItems: "baseline" }}
                            >
                              <Select
                                showSearch
                                value={x.name}
                                onChange={(e) => {
                                  variable[i].name = e;
                                  setVariable([...variable]);
                                }}
                                placeholder="Variable"
                                options={varSrc.map((i) => ({
                                  value: i,
                                  label: (
                                    <span
                                      dangerouslySetInnerHTML={{ __html: i }}
                                    />
                                  ),
                                }))}
                                style={{ width: 120 }}
                              />
                              <Select
                                onChange={(e) => {
                                  variable[i].operator = e;
                                  setVariable([...variable]);
                                }}
                                value={x.operator}
                                style={{ width: 70 }}
                                options={[
                                  { label: "=", value: "=" },
                                  { label: "<>", value: "<>" },
                                ]}
                              />
                              <Input
                                value={x.value}
                                onInput={(e) => {
                                  variable[i].value = e.target.value;
                                  setVariable([...variable]);
                                }}
                                style={{ flex: 1 }}
                                placeholder="Value"
                              />
                              <Button
                                title="Remove"
                                onClick={() => remove(x)}
                                icon={<MinusOutlined />}
                              />
                            </Space.Compact>
                          ))}
                        </>
                      ) : null}
                    </Space>
                    {isWhen ? (
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          gap: 8,
                          justifyContent: "end",
                        }}
                      >
                        {variable.length > 1 ? (
                          <Popconfirm
                            placement="left"
                            title="Clear all Variable"
                            onConfirm={() => setVariable([])}
                            okText="Clear"
                            okButtonProps={{ danger: true }}
                            cancelText="Cancel"
                          >
                            <Button size="middle">Clear All</Button>
                          </Popconfirm>
                        ) : null}

                        {varSrc.length === 0 ? (
                          <div style={{ textAlign: "center", width: "100%" }}>
                            No variables found in the content
                          </div>
                        ) : (
                          <Button
                            style={{ marginBottom: 16 }}
                            size="middle"
                            icon={<PlusOutlined />}
                            onClick={add}
                          >
                            New variable
                          </Button>
                        )}
                      </div>
                    ) : null}
                  </Space>
                </Flex>
              </Form>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      {/* <Border.Divider /> */}

      <Border
        disabled={!id || loading}
        title="Editor"
        style={{ marginTop: 16, padding: 4 }}
      >
        {loadingE && (
          <Skeleton style={{ padding: 8 }} loading active paragraph />
        )}
        <Editor
          tinymceScriptSrc="/static/tinymce/tinymce.js"
          onInit={(_, editor) => {
            editorRef.current = editor;
            setLoadingE(false);
          }}
          onBlur={() => setUpdate(true)}
          initialValue=""
          tabIndex={0}
          init={configTiny}
        />
      </Border>
      <ShowOutlineEdit
        open={openShowOutline}
        setOpen={setOpenShowOutline}
        tag={tag}
        setGuid={(id) => {
          setId(id);
          navi("?tab=edit&id=" + id);
        }}
        setSrc={setSrc}
      />
      <ShowOutline
        open={openShowOutline1}
        setOpen={setOpenShowOutline1}
        industryId={item.tags}
        tag={tag}
      />
    </div>
  );
}
