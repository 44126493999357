import React from 'react'

/**
 * @param {{style: React.CSSProperties}} param0 
 * @returns 
 */

export default function Center({className, height, width, children, direction, style}) {
  return (
    <div className={className} style={{...style, display: 'flex', justifyContent: 'center', alignItems: 'center', width, height, flexDirection: direction??'row'}}>
        {children}
    </div>
  )
}
