import React from "react";
import { AuthProvider } from "../utils/auth";
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from "./CopyRight";

function LoginLayout({ children }) {

  const theme = createTheme({
    palette: {
      primary: {
        // light: '#757ce8',
        main: '#016c74',
        // dark: '#002884',
        // contrastText: '#fff',
        text: {
          primary: '#016c74'
        },
      },
      secondary: {
        // light: '#ff7961',
        main: '#016c74',
        // dark: '#ba000d',
        // contrastText: '#000',
      },
    },
  });


  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box>
          {children}
        </Box>
        <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
          <Copyright />
        </Box>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default LoginLayout;
