import { App, Button, Card, Empty, Flex, Input, List, Modal, Select, Space, Tree, notification } from 'antd'
import React, { memo, useEffect, useMemo, useState } from 'react'
import axios, { API, auth } from '../../configs'
import { CaretDownOutlined, CaretRightOutlined, DownOutlined, SearchOutlined } from '@ant-design/icons';
import HighText from '../../components/HL';
import { renderDataTree } from '../../components/ShowOutlineEdit';
const Render = ({ data, dataAll, onClick, search }) => {
    const [dataInput, setDataInput] = useState([])
    useEffect(() => {
        setDataInput(data.map(x => ({ ...x, show: false, childern: dataAll.filter(j => (new RegExp(`^${x.section}.\\d+$`).test(j.section))) })))
    }, [data, dataAll])
    const showData = (item) => {
        if (item.childern.length !== 0) {
            setDataInput(i => i.map(x => x.key === item.key ? ({
                ...x, show: !x.show
            }) : x))
        }


    }
    return dataInput.filter(x => !search || (x.section + x.sectionTitle).includes(search) || search.includes(x.section)).map((item) =>
        <React.Fragment key={item.key} >
            <Card onClick={() => showData(item)} size="small" hoverable className="outline-item">
                <Flex >
                    <Flex onClick={() => onClick(item.key)} className="hover-line" gap={4}>
                        <strong>Section <HighText search={search}>{item.section}</HighText>:</strong> <HighText search={search}>{item.sectionTitle}</HighText>
                    </Flex>
                    <span style={{ flex: 1 }}></span>
                    {item.childern.length > 0 ? item.show ? <CaretDownOutlined /> : <CaretRightOutlined /> : null}
                </Flex>
            </Card>
            {item.show ?
                <div style={{ marginLeft: 32, display: 'flex', flexDirection: 'column', gap: 4 }}>
                    <Render onClick={onClick} search={search} dataAll={dataAll} data={item.childern} />
                </div> : null}
        </React.Fragment>
    )
}
const ListVarView = ({ modal, setModal }) => {
    const [loading, setLoading] = useState(true)
    const [variable, setVariable] = useState([])
    useEffect(() => {
        setLoading(true)
        if (modal.id)
            API.variable.getContent({ section_id: modal.id }).then(({ data }) => {
                if (data) {
                    setVariable(data.result)
                    // setModal(x => ({ ...x, loading: false }))
                }
            }).finally(() => setLoading(false))
    }, [modal.id])
    function setDefinition(input, id) {
        setVariable(k => k.map(i => i.variable_id === id ? ({ ...i, definition: input }) : i))
    }
    function saveData() {
        setLoading(true)
        API.variable.updateVariableContent({ variable }).then(({ data }) => {
            if (data) {
                if (data && data.detail) {
                    notification.success({ message: data.detail })
                    setModal({ ...modal, open: false })
                }
            }
        }).finally(() => setLoading(false))
    }
    return (
        <Modal {...modal} onCancel={() => setModal({ ...modal, open: false })} okButtonProps={{disabled: variable.length === 0}} okText='Save' onOk={saveData}>
            <Flex vertical>
                <List loading={loading} style={{ maxHeight: 300, marginBottom: 16, overflowY: 'auto' }} dataSource={variable.filter(x => !x.value)}
                    renderItem={(i) => <List.Item style={{ display: 'flex', alignItems: 'center', gap: 8, paddingRight: 16 }}>
                        <strong style={{ width: 120, textAlign: 'right' }}>{i.name} :</strong>
                        <Input value={i.definition} onInput={(e) => setDefinition(e.target.value, i.variable_id)} placeholder={`Definition (${i.type})`} style={{ flex: 1 }} />
                    </List.Item>} />
            </Flex>
        </Modal>)
}
export default function VariableManagement() {
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [selectTag, setselectTag] = useState([]);
    const [tag, setTag] = useState([])
    const [modal, setModal] = useState({})
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    useEffect(() => {
        API.tags.getAll().then(({ data }) => {
            if (data) setTag(data.result.map(x => ({ label: x.name, value: x.industry_id })))
        })
    }, [])

    const selectSection = (_, { key }) => {
        setModal({ open: true, title: "Variable Definition", id: key.split('___')?.at(-1) })
    }
    useEffect(() => {
        if (selectTag) {
            axios
                .get("/sections/get-section", auth({ IndustryId: JSON.stringify(selectTag) }))
                .then((rs) => {
                    var dataTmp = rs.data.result
                    setData(dataTmp);
                });
        }
    }, [selectTag]);
    const onExpand = (expandedKeysValue) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };
    return (

        <Flex vertical style={{ maxWidth: 1024, margin: '0 auto' }}>
            <ListVarView modal={modal} setModal={setModal} />
            <Select style={{ display: 'block', marginBottom: 16 }}
                mode="multiple"
                onChange={(e) => {
                    setselectTag(e);
                }}
                value={selectTag}
                options={tag}
                placeholder="Select tag"
                showSearch
                filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            />
            <div>
                <Input style={{ marginBottom: 10 }}
                    value={search}
                    onInput={(e) => setSearch(e.target.value)}
                    placeholder="Search"
                    prefix={<SearchOutlined />}
                />
                <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                    {data.length > 0 ? (
                        <Tree onExpand={onExpand} autoExpandParent={autoExpandParent}
                            expandedKeys={expandedKeys} onClick={selectSection} switcherIcon={<DownOutlined />} treeData={renderDataTree(
                                data.filter(i => `section ${i.name}: ${i.title}`.toLowerCase().includes(search.toLowerCase()))
                                , search)} />
                        // <Render search={search} onClick={selectSection} data={data.filter(x => x.section?.split(".").length === 1)} dataAll={data} />
                    ) : (
                        <Empty />
                    )}
                </div>
            </div>
        </Flex>
    )
}

